var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Acumulado de inspecciones"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.specificCurrentMonthFilters.startEndDate},
                {category: 'prevMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.specificPrevMonthFilters.startEndDate},
              ], true)
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: specificCurrentMonthFilters.startEndDate},\n                {category: 'prevMonth', field: 'inspections', queryMethod: getInspections, queryFilter: specificPrevMonthFilters.startEndDate},\n              ], true)\n            }"}],attrs:{"records":_vm.specificChartData.currentMonth.inspections,"prev-month-records":_vm.specificChartData.prevMonth.inspections,"criterias":[
              {
                name: 'Agendada',
                color: '#FAD901',
                filter: (record) => record.executive.id === _vm.idEmployee,
              },
              {
                name: 'Cancelada',
                color: '#F03738',
                filter: (record) => record.executive.id === _vm.idEmployee && record.closingReason?.description === 'Cancelada',
              },
              {
                name: 'Finalizada',
                color: '#13CE66',
                filter: (record) => record.executive.id === _vm.idEmployee && Boolean(record.inspectorQualification),
              },
              {
                name: 'Validada',
                color: '#7D67BC',
                filter: (record) => record.executive.id === _vm.idEmployee && Boolean(record.supervisorQualification),
              },
              {
                name: 'Compradas',
                color: '#6185DB',
                filter: (record) => record.executive.id === _vm.idEmployee && Boolean(record.bought),
              },
            ],"path-to-date":['createdAt'],"data-transformer":_vm.normaliceData,"type":"line","accumulated":true,"dates":_vm.dates,"show-total":false,"sort-legends":false}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }